import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Menu from "src/components/moreMenu"

import NickelledLogoO from "../images/svgs/nickelled-logo-o.svg"

const examples = [
  {
    name: "Flows",
    description: "Walk users through your app easily",
    href: "/example/",
  },
  {
    name: "Hotspots",
    description: "Highlight individual elements on a page",
    href: "/example/person/",
  },
  {
    name: "Help Assistant",
    description: "Launch help from an on-page widget",
    href: "/example/settings/",
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const menuToggle = () => toggleExpansion(!isExpanded)

  return (
    <header class="sticky top-0 z-50 bg-white">
      <nav class="max-w-7xl mx-auto px-1 md:px-0">
        <div class="flex justify-between items-center p-4 md:justify-start md:space-x-10">
          <div>
            <a href="/" class="flex">
              <span class="sr-only">Nickelled</span>
              <NickelledLogoO class="h-8 w-auto sm:h-12"></NickelledLogoO>
            </a>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-oOrange"
              onClick={menuToggle}
            >
              <span class="sr-only">Open menu</span>
              {
                //<!-- Heroicon name: outline/menu -->
              }
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div class="hidden md:flex-1 md:flex md:items-center md:justify-end">
            <nav class="flex space-x-10">
              <Link
                to="/product/"
                class="text-base font-medium text-gray-500 hover:text-gray-900"
                activeClassName="text-base font-medium text-oOrange"
              >
                Product
              </Link>
              <Link
                to="/pricing/"
                class="text-base font-medium text-gray-500 hover:text-gray-900"
                activeClassName="text-base font-medium text-oOrange"
              >
                Pricing
              </Link>
              <Link
                to="/blog/"
                class="text-base font-medium text-gray-500 hover:text-gray-900"
                activeClassName="text-base font-medium text-oOrange"
              >
                Blog
              </Link>
              <Menu />
              <Link
                to="/request-demo/"
                class="text-base font-medium text-gray-500 hover:text-gray-900"
                activeClassName="text-base font-medium text-oOrange"
              >
                Book a Call
              </Link>
            </nav>
            <div class="flex items-center md:ml-12">
              <a
                href="https://dashboard.nickelled.com/account/login"
                class="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Sign in
              </a>

              <a
                href="/request-demo/"
                class="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-semibold text-white bg-gradient-to-r from-oRed to-oOrange"
              >
                Free Demo
              </a>
            </div>
          </div>
        </div>

        {
          //<!--
          //Mobile menu, show/hide based on mobile menu state.
          //
          // Entering: "duration-200 ease-out"
          //   From: "opacity-0 scale-95"
          //  To: "opacity-100 scale-100"
          //Leaving: "duration-100 ease-in"
          //  From: "opacity-100 scale-100"
          //   To: "opacity-0 scale-95"
          //-->
        }
        <div
          className={
            "absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden " +
            (isExpanded
              ? "opacity-100 duration-150 ease-out z-40 scale-100"
              : "opacity-0 duration-100 ease-in scale-95 hidden")
          }
        >
          <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div class="pt-5 pb-6 px-5">
              <div class="flex items-center justify-between">
                <div>
                  <NickelledLogoO class="h-8 w-auto sm:h-10"></NickelledLogoO>
                </div>
                <div class="-mr-2">
                  <button
                    type="button"
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-oOrange"
                    onClick={menuToggle}
                  >
                    <span class="sr-only">Close menu</span>
                    {
                      //<!-- Heroicon name: outline/x -->
                    }
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="py-6 px-5">
              <div class="grid grid-cols-2 gap-4">
                <Link
                  to="/product/"
                  class="text-base font-medium text-gray-900 hover:text-gray-700"
                  activeClassName="text-base font-medium text-oOrange"
                >
                  Product
                </Link>

                <Link
                  to="/pricing/"
                  class="text-base font-medium text-gray-900 hover:text-gray-700"
                  activeClassName="text-base font-medium text-oOrange"
                >
                  Pricing
                </Link>

                <Link
                  to="/request-demo/"
                  class="text-base font-medium text-gray-900 hover:text-gray-700"
                  activeClassName="text-base font-medium text-oOrange"
                >
                  Request Demo
                </Link>

                <Link
                  to="/blog/"
                  class="text-base font-medium text-gray-900 hover:text-gray-700"
                  activeClassName="text-base font-medium text-oOrange"
                >
                  Blog
                </Link>

                <Link
                  to="/saas-resources/"
                  class="text-base font-medium text-gray-900 hover:text-gray-700"
                  activeClassName="text-base font-medium text-oOrange"
                >
                  SaaS Resources
                </Link>

                <Link
                  to="/books/"
                  class="text-base font-medium text-gray-900 hover:text-gray-700"
                  activeClassName="text-base font-medium text-oOrange"
                >
                  Our Books
                </Link>

                <a
                  href="https://help.nickelled.com"
                  class="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Support
                </a>
              </div>
              <div class="mt-6">
                <Link
                  to="/request-demo/"
                  class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-semibold text-white bg-gradient-to-r from-oRed to-oOrange"
                >
                  Free demo
                </Link>
                <p class="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?
                  <a
                    href="https://dashboard.nickelled.com/account/login"
                    class="ml-3 text-oOrange"
                  >
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
