import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader"

import Header from "../components/header"
import Footer from "../components/footer"

import QuoteSign from "../images/svgs/quoteSign.svg"

import { OutboundLink } from "gatsby-plugin-google-gtag"

export default class PricingTable extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div class="">
          <div class="">
            <SEO title="Nickelled Pricing" />
            <div className="w-full bg-gray-100">
              <div className="max-w-7xl mx-auto px-1 md:px-0 py-8">
                <div class="text-center mb-16">
                  <p class="text-base font-semibold text-oOrange tracking-wide uppercase">
                    For All Sizes of Business
                  </p>
                  <h1 class="mt-2 pb-4 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                    Nickelled Pricing
                  </h1>
                </div>
                <div class="flex">
                  <div class="flex-1 w-1/2 p-6">
                    <h2 class="text-4xl my-3">Pricing</h2>
                    <p class="text-gray-900 text-base md:text-lg my-4">
                      Our pricing structure is calculated to meet your
                      requirements. We look at your application landscape and
                      user usage, to deliver maximum value, in minimum time.
                    </p>
                    <Link
                      to="/request-a-quote/"
                      class="hidden sm:hidden md:hidden my-6 md:my-0 lg:inline-flex items-center text-center px-6 py-4 text-xl font-semibold rounded-lg text-white bg-gradient-to-r from-oRed to-oOrange "
                    >
                      Request a quote
                    </Link>
                  </div>
                  <div className="flex-1 p-6">
                    <div className="border border-oOrange rounded-lg p-4 md:p-12 text-left relative">
                      <p className="text-gray-900 mt-0 text-base md:text-lg">
                        We found Nickelled whilst looking for a solution to
                        onboard our users without the need for extensive dev
                        work - and we haven't looked back since! Perfect
                        solution, great product, and brilliant support.
                      </p>
                      <p class="text-base md:text-lg mb-2">- Jessica Golding</p>
                      <p className="uppercase text-xs mt-0 mb-0">
                        Director of Customer Experience, 90Seconds
                      </p>
                      <div className="hidden md:block absolute bottom-6 right-12 text-gray-900">
                        <QuoteSign class="fill-current h-16"></QuoteSign>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white"></div>
            <div className="max-w-7xl mx-auto py-8">
              <h3 className="text-center font-bold my-8">
                Join thousands of customers who have used Nickelled to
                <br /> increase software adoption, reduce help desk volume, cut
                onboarding time and improve user experience.
              </h3>
              <hr className="border-gray-100" />
            </div>
            <div
              id="frequently-asked-questions"
              className="max-w-7xl mx-auto py-8"
            >
              <h3 class="my-4">Frequently Asked Questions</h3>
              <div className="grid grid-cols-1 gap-y-4">
                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold">Do you offer discounts?</p>
                  <p className="mt-4 mb-0">
                    Yes. We've got discounts for government, non-profits and
                    startups too.
                  </p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold">
                    Do you charge for monthly active users viewing my guides?
                  </p>
                  <p className="mt-4 mb-0">
                    No. You can show your guides to as many people as you want.
                  </p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold">Can we cancel at any time?</p>
                  <p className="mt-4 mb-0">
                    Yes! For annual subscriptions, you can cancel at any time
                    and we won't bill you again when your subscription expires.
                  </p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold"> What support do you offer?</p>
                  <p className="mt-4 mb-0">
                    We offer global support from centers in Europe, America and
                    Asia.
                  </p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold">
                    Do your guides support mobile/tablet?
                  </p>
                  <p className="mt-4 mb-0">
                    Yes, Nickelled guides are fully responsive.
                  </p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold"> Do you support native apps?</p>
                  <p className="mt-4 mb-0">
                    No. We support web applications only.
                  </p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold">
                    Can I use multiple languages?
                  </p>
                  <p className="mt-4 mb-0">
                    Yes, Nickelled supports 16 languages out of the box and more
                    are available on request.
                  </p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold"> Can I embed my CSS styling?</p>
                  <p className="mt-4 mb-0"> Yes.</p>
                </div>

                <div className="rounded-md bg-gray-100 border border-gray-200 p-4">
                  <p className="mt-0 font-bold">
                    Can I embed my custom javascript?
                  </p>
                  <p className="mt-4 mb-0"> Yes.</p>
                </div>
              </div>
              <p className="text-lg font-bold">
                <span className="text-oOrange">
                  What if I have more questions?{" "}
                </span>
                We'd be happy to answer them.
              </p>
            </div>
          </div>
        </div>
        <LiveChatLoaderProvider providerKey="ii6t4rrk" provider="intercom">
          <Intercom color="#FF9C43" />
        </LiveChatLoaderProvider>
        <Footer></Footer>
      </>
    )
  }
}
